
function isEmail(emailAdress) {
    if (emailAdress) {
        let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailAdress.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

function isNumber(value) {
    return typeof value === 'number' && isFinite(value);
}

function isArray(value) {
    return value && typeof value === 'object' && value.constructor === Array;
}

function isFunction(value) {
    return typeof value === 'function';
}

function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

function isNull(value) {
    return value === null;
}

function isUndefined(value) {
    return typeof value === 'undefined';
}

function isBoolean(value) {
    return typeof value === 'boolean';
}

function isRegExp(value) {
    return value && typeof value === 'object' && value.constructor === RegExp;
}

function isError(value) {
    return value instanceof Error && typeof value.message !== 'undefined';
}

function isDate(value) {
    return value instanceof Date;
}

function isSymbol(value) {
    return typeof value === 'symbol';
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function tryParseInt(parseValue, defaultValue) {
    
    let _default = 0;
    if(isNumber(defaultValue)){
        _default = defaultValue 
    }

    let value = _default;
    if (parseValue != null && parseValue != undefined) {
        try {
            let parsedValue = parseInt(parseValue);
            if(isNaN(parsedValue)){
                parsedValue = _default;
            }

            value = parsedValue;
        } catch (ex) { }
    }
    return  value;
}

function tryParseString(parseValue, defaultValue) {
    let _default = '';
    if (defaultValue != null || defaultValue != undefined) {
        _default = defaultValue.toString();
    }

    let value = _default;
    if (parseValue != null && parseValue != undefined) {
        value = parseValue.toString();
    }

    return value;
}

module.exports = {
    isEmail: isEmail,
    isString: isString,
    isNumber: isNumber,
    isArray: isArray,
    isFunction: isFunction,
    isObject: isObject,
    isNull: isNull,
    isUndefined: isUndefined,
    isBoolean: isBoolean,
    isRegExp: isRegExp,
    isError: isError,
    isDate: isDate,
    isSymbol: isSymbol,
    isEmpty: isEmpty,

    tryParseInt: tryParseInt,
    tryParseString: tryParseString,
}