import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/style.scss';
import SingularStatus from './components/SingularStatus';
import reportWebVitals from './reportWebVitals';

const firebase = require('firebase');
const core = {
  apiKey: "AIzaSyAio3OzdJyS0XSVm8_AiUqa7Ux5z716RrA",
  authDomain: "fiery-torch-2122.firebaseapp.com",
  databaseURL: "https://fiery-torch-2122.firebaseio.com",
  storageBucket: "fiery-torch-2122.appspot.com",
  projectId: "fiery-torch-2122"
};

const firebaseApp = firebase.initializeApp(core);
const rtdbRef = firebaseApp.database().ref();

ReactDOM.render(
  <React.StrictMode>
    <SingularStatus rtdbRef={rtdbRef} />
  </React.StrictMode>,
  document.getElementById('singularStatus')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
